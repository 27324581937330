import React from 'react';
import styles from './HeroNew.module.scss';
import { object } from 'prop-types';
import { RichText } from 'prismic-reactjs';
import Button, { VARIANT } from '../../../../components/Button/Button';
import Image from '@components/Image/Image';

const HeroNew = ({ primary }) => {
  const {
    previewimage: previewImage,
    // modalctabuttontext: modalCtaButtonText,
    // modalctabuttonlink: modalCtaButtonLink,
    // videobuttontext: videoButtonText,
    // modalvideo: modalVideo,
    title,
    description,
    buttonlink,
    buttontext,
    subtitle,
    // secondbuttonlink,
    // secondbuttontext,
  } = primary;

  // console.log('handleMoveToWhatYouNeed', handleMoveToWhatYouNeed);
  // console.log('primary', primary);
  // console.log('buttonlink', buttonlink);

  return (
    <div className={styles.hero}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.sub_title}>
            <RichText render={subtitle?.richText} />
          </div>
          <div className={styles.title}>
            <RichText render={title?.richText} />
          </div>
          <div className={styles.descr}>
            <RichText render={description?.richText} />
          </div>

          {/* {console.log('lllll', buttonlink?.richText[0]?.text)} */}

          <div className={styles.buttonWrapper}>
            <Button
              variant={VARIANT.PRIMARY}
              to={buttonlink?.richText[0]?.text}
            >
              <RichText render={buttontext?.richText} />
            </Button>
          </div>
        </div>

        <div className={styles.imageWrapper}>
          <div className={styles.img_wrap}>
            <Image className={styles.image} image={previewImage} />
          </div>
        </div>
      </div>
    </div>
  );
};

HeroNew.propTypes = {
  primary: object,
  handleMoveToWhatYouNeed: () => {},
};

export default HeroNew;
