import React from 'react';
import styles from './SecurePrivacy.module.scss';
import { any } from 'prop-types';
import { RichText } from 'prismic-reactjs';
import Image from '../../../../components/Image/Image';
import Button, { VARIANT } from '../../../../components/Button/Button';

const SecurePrivacy = (datas) => {
  // console.log('datas', datas);

  const { primary, items } = datas;

  return (
    <div className={styles.secure_privacy_wrapper}>
      <div className={styles.secure_privacy_wrapper_img} />

      <div className={styles.container}>
        <div className={styles.secure_privacy_all_content}>
          <div className={styles.title}>
            <RichText render={primary?.title?.richText} />
          </div>

          <div className={styles.secure_privacy_card_wrapper}>
            {items &&
              items?.map((item, index) => (
                <div className={styles.secure_privacy_card} key={index}>
                  <div className={styles.card_content_wraper}>
                    <div className={styles.icon_wraper}>
                      <Image image={item?.card_image} />
                    </div>
                    <div className={styles.desc_wrapper}>
                      <div className={styles.card_title}>
                        <RichText render={item?.card_title?.richText} />
                      </div>
                      <div className={styles.card_sub_title}>
                        <RichText render={item?.card_sub_title?.richText} />
                      </div>
                      {/* <p>{item?.card_description?.richText[0]?.text}</p> */}
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div className={styles.button}>
            <Button to={primary?.button_link?.url} variant={VARIANT.PRIMARY}>
              <RichText render={primary?.button_text?.richText} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

SecurePrivacy.propTypes = {
  datas: any,
};

export default SecurePrivacy;
