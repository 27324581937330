import React from 'react';
import PropTypes from 'prop-types';
import BreadcrumbsSemanticMarkup from '@components/BreadcrumbsMarkup/BreadcrumbsMarkup';
import HeroNew from './components/Hero';
import style from './ResellerPageNew.module.scss';
import SecurePrivacy from './components/SecurePrivacy';
import TrustedByCompanies from './components/TrustedByCompanies/TrustedByCompanies';

const ResellerPageNew = ({ content, metatitle, canonical }) => {
  return (
    <div className={style.container}>
      {content.map((item, index) => {
        switch (item.slice_type) {
          case 'hero_new':
            return <HeroNew key={index} {...item} />;
          case 'card_view':
            return <SecurePrivacy key={index} {...item} />;
          case 'trusted_by_companies':
            return <TrustedByCompanies key={index} {...item} />;
          default:
            return null;
        }
      })}
      <BreadcrumbsSemanticMarkup
        pageTitle={metatitle.text}
        pageUrl={canonical.text}
      />
    </div>
  );
};

ResellerPageNew.propTypes = {
  content: PropTypes.array.isRequired,
  canonical: PropTypes.object.isRequired,
  metatitle: PropTypes.object.isRequired,
};

export default ResellerPageNew;
